import {
  CRONE_DESCRIPTION,
  CRONE_TAGLINE,
  CRONE_URI,
} from '@crone/shared/utils/constants';
import { DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps = {
  defaultTitle: 'Crone',
  titleTemplate: 'Crone · %s',
  description: CRONE_DESCRIPTION,
  facebook: {
    appId: '667461174982660',
  },
  openGraph: {
    type: 'website',
    locale: 'en_US',
    title: 'Crone',
    description:
      "Feel smarter. Take free, interactive lessons from history's greatest minds.",
    images: [
      {
        url: `${CRONE_URI}/shared-dupe/og/2024-04-11-banner-min.png`,
        secureUrl: CRONE_URI + '/shared-dupe/og/2024-04-11-banner-min.png',
        width: 1200,
        height: 675,
        type: 'image/png',
        alt: CRONE_TAGLINE,
      },
    ],
    url: CRONE_URI,
    siteName: 'Crone',
  },
  twitter: {
    handle: '@croneai',
    site: '@croneai',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/favicon/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
  ],
  additionalMetaTags: [
    {
      name: 'msapplication-TileColor',
      content: '#2b5797',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
    {
      name: 'apple-itunes-app',
      content: 'app-id=6476424527',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
    {
      name: 'googlebot',
      content: 'index, follow',
    },
  ],
};

export default config;
