export const MIN_ANSWER_LENGTH_SOFT = 28; // where we warn users
export const MIN_ANSWER_LENGTH_HARD = 12; // where we won't record or embed

export const MAX_WIDTH = 760;

export const DEFAULT_PLAYBACK_RATE = 1.6; // it's x10 to deal with a edge case bug

export const LIBRARY_PATHS_KEY = 'libraryPaths';

// for local storage
export const RECENT_USER_ACTIVITY_KEY = {
  visit: 'recentUserVisit',
  completion: 'recentUserCompletion',
};

export const CRONE_TAGLINE = 'Feel smarter.';
export const CRONE_DESCRIPTION =
  "Interactive lessons from humanity's greatest minds";

export const CRONE_URI = process.env.CRONE_URI || 'https://www.crone.ai';
// difference from one above is that APP_URI points to localhost in dev
export const APP_URI = process.env.APP_URI || 'https://www.crone.ai';

export const MIN_WRITING_SCORE = 5;

// TODO: shorten when we have more traffic
export const CACHE_TTL = 3600 * 24; // 1 day
