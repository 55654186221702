import {
  CourseComponentRecType,
  Prisma,
  UserProfileToCourse,
  UserSubscribedStatus,
} from '@prisma/client';

export type UserProfileWithRollups = Prisma.UserProfileGetPayload<{
  include: {
    userDayRollup: true;
  };
}>;

export type UserProfileWithUsis = Prisma.UserProfileGetPayload<{
  include: {
    userStoryInstances: true;
  };
}>;

export type UserProfileToCourseDict = Record<string, UserProfileToCourse>;

export interface UserRecPosterInfo {
  recReason: string;
  recType: CourseComponentRecType;
  imgPath: string | null;
  author: string | null;
  themes: string[] | null;
  lessonTitle: string;
  coursePretitle: string | null;
  courseTitle: string | null;
  lessonNumString: string;
  snippet?: string;
}

export const pendingSubscriptionStatuses: UserSubscribedStatus[] = [
  'PENDING',
  'INCOMPLETE',
];

export const inactiveSubscriptionStatuses: UserSubscribedStatus[] = [
  'CANCELED',
  'PAST_DUE',
];

export const authSubscriptionStatuses: UserSubscribedStatus[] = [
  'TRIAL',
  'SUBSCRIBED',
  'LIFETIME',
  'DEMO',
];
