// import Constants from 'expo-constants';
import { SolitoImageProvider as SolitoImageProviderOG } from 'solito/image';

let localhost: string | undefined;
const PROTOCOL = 'http:';
const localhostRegex = new RegExp(`${PROTOCOL}//localhost:`, 'g');

export function getLocalhost() {
  if (localhost !== undefined) {
    return localhost;
  }
  const debuggerHost = process.env.EXPO_PUBLIC_APP_URI;
  localhost = debuggerHost?.split(':')[0] ?? 'localhost';
  return localhost;
}

export function replaceLocalhost(address: string) {
  return address.replace(
    localhostRegex,
    () => `${PROTOCOL}//${getLocalhost()}:`
  );
}

export const getImageUrl = () => {
  const imageUrl = `${process.env.EXPO_PUBLIC_APP_URL}`;
  return replaceLocalhost(imageUrl);
};

export const SolitoImageProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  return (
    <SolitoImageProviderOG
      nextJsURL={getImageUrl() as `http:${string}` | `https:${string}`}
    >
      {children}
    </SolitoImageProviderOG>
  );
};
