import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Audio, AVPlaybackStatus } from 'expo-av';

const FADE_OUT_STEPS = 10;
const FADE_OUT_INTERVAL = 200; // this multiplied by fadeOutSteps should be the total fade out time

interface AudioControlContextType {
  loadSound: ({
    uri,
    initialRate,
    shouldPlay,
    startFrom,
    endAt,
  }: {
    uri: string;
    initialRate: number;
    shouldPlay: boolean;
    startFrom?: number | undefined | null;
    endAt?: number | undefined | null;
  }) => void;
  play: () => void;
  pause: () => void;
  stop: () => void;
  unload: () => void;
  startOver: () => void;
  setRate: (rate: number) => void;
  setVolume: (volume: number) => void;
}

interface AudioStatusContextType {
  positionMillis: number;
  durationMillis?: number;
  isPlaying: boolean;
}

const AudioControlContext = createContext<AudioControlContextType | undefined>(
  undefined
);
const AudioStatusContext = createContext<AudioStatusContextType | undefined>(
  undefined
);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const soundRef = useRef<Audio.Sound | null>(null);
  const isLoadingRef = useRef(false); // Use useRef for the loading lock
  const endAtRef = useRef<number | undefined>(undefined); // Ref for endAt
  const currentRate = useRef<number>(1);
  const fadeOutStarted = useRef(false);
  const [status, setStatus] = useState<AudioStatusContextType>({
    isPlaying: false,
    positionMillis: 0,
  });

  const loadSound = useCallback(
    async ({
      uri,
      initialRate,
      shouldPlay,
      endAt,
      startFrom,
    }: {
      uri: string;
      initialRate: number;
      shouldPlay: boolean;
      endAt?: number;
      startFrom?: number;
    }) => {
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;
      endAtRef.current = endAt;
      fadeOutStarted.current = false;
      currentRate.current = initialRate;

      const shouldPlayForReal =
        shouldPlay && (endAt === undefined || endAt > 1.5);

      try {
        if (soundRef.current) await soundRef.current.unloadAsync();
        console.log('Loading sound:', uri, shouldPlayForReal, initialRate);
        // const { sound: newSound } = await Audio.Sound.createAsync(
        const { sound: newSound } = await Audio.Sound.createAsync(
          { uri },
          {
            shouldPlay: shouldPlayForReal,
            rate: initialRate,
            shouldCorrectPitch: true,
          }
        );
        newSound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
        soundRef.current = newSound;
        if (startFrom) {
          const position = startFrom * 1000 - 500 * initialRate;
          newSound.setPositionAsync(position);
        }
        setRate(initialRate);
      } catch (error) {
        // console.error('Error loading sound:', error);
      }
      isLoadingRef.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingRef, soundRef]
  );

  const onPlaybackStatusUpdate = async (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      setStatus({
        isPlaying: status.isPlaying,
        positionMillis: status.positionMillis,
        durationMillis: status.durationMillis,
      });
      const endAtMillis = (endAtRef.current || 0) * 1000;

      if (
        endAtMillis !== 0 &&
        status.positionMillis >=
          endAtMillis - FADE_OUT_INTERVAL * FADE_OUT_STEPS - 1000 &&
        status.isPlaying
      ) {
        fadeOut();
      }
    }
  };

  const fadeOut = useCallback(async () => {
    try {
      if (!soundRef.current || fadeOutStarted.current) return;
      fadeOutStarted.current = true;
      for (let i = 1; i <= FADE_OUT_STEPS; i++) {
        await soundRef.current?.setVolumeAsync(1 - i / FADE_OUT_STEPS);
        await new Promise((resolve) =>
          setTimeout(resolve, FADE_OUT_INTERVAL / currentRate.current)
        );
      }
      await soundRef.current.pauseAsync();
      soundRef.current.setVolumeAsync(1);
    } catch (error) {
      console.error('Error fading out:', error);
    }
  }, [soundRef, fadeOutStarted]);

  const play = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current?.playAsync();
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  }, [soundRef]);

  const pause = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current?.pauseAsync();
    } catch (error) {
      console.error('Error pausing sound:', error);
    }
  }, [soundRef]);

  const startOver = useCallback(async () => {
    try {
      if (!soundRef.current) return;
      await soundRef.current?.setPositionAsync(0);
      await soundRef.current?.playAsync();
    } catch (error) {
      console.error('Error starting over:', error);
    }
  }, [soundRef]);

  const unload = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current?.unloadAsync();

      // reset position and duration
      setStatus({
        isPlaying: false,
        positionMillis: 0,
        durationMillis: 0,
      });
    } catch (error) {
      console.error('Error unloading sound:', error);
    }
  }, [soundRef]);

  const stop = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current?.stopAsync();
    } catch (error) {
      console.error('Error stopping sound:', error);
    }
  }, [soundRef]);

  const setRate = useCallback(
    async (rate: number) => {
      try {
        if (soundRef.current) await soundRef.current?.setRateAsync(rate, true);
        currentRate.current = rate;
      } catch (error) {
        console.error('Error setting rate:', error);
      }
    },
    [soundRef]
  );

  const setVolume = useCallback(
    async (volume: number) => {
      try {
        if (soundRef.current) await soundRef.current?.setVolumeAsync(volume);
      } catch (error) {
        console.error('Error setting volume:', error);
      }
    },
    [soundRef]
  );

  // FadeOut method refactored for smoother transition
  // const fadeOut = useCallback(async () => {

  // }, []);

  const audioControlValue = useMemo(
    () => ({
      loadSound,
      play,
      pause,
      stop,
      unload,
      setRate,
      startOver,
      setVolume,
    }),
    [loadSound, play, pause, stop, unload, setRate, startOver, setVolume]
  );
  const audioStatusValue = useMemo(() => status, [status]);

  return (
    <AudioControlContext.Provider value={audioControlValue}>
      <AudioStatusContext.Provider value={audioStatusValue}>
        {children}
      </AudioStatusContext.Provider>
    </AudioControlContext.Provider>
  );
};

export const useAudioControl = () => {
  const context = useContext(AudioControlContext);
  if (!context) {
    throw new Error('useAudioControl must be used within an AudioProvider');
  }
  return context;
};

export const useAudioStatus = () => {
  const context = useContext(AudioStatusContext);
  if (!context) {
    throw new Error('useAudioStatus must be used within an AudioProvider');
  }
  return context;
};
